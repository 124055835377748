import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'slick-carousel';
import 'jquery-match-height';
import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import Forms from './components/forms';
import LeadForm from './components/lead-form.vue';
import Scroller from './components/scroller';

Validator.extend('tel', {
  getMessage: () => `Please enter a valid phone number. It has to start with 0 or +44 and contain 9-10 numbers.`,
  validate: value => new RegExp('(?:\\+?44)[0-9]{9,10}|(?:0)[0-9]{10}').test(value.trim().replace(/ /g, '')),
});

Validator.extend('postcode', {
  getMessage: () => `Please enter a valid postcode.`,
  validate: value => new RegExp('^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\\s?[0-9][A-Za-z]{2}))$').test(value.trim()),
});

window.events = new Vue();

class App {
  static init() {
    (new Forms()).init();
    (new Scroller()).watch();

    Vue.use(VeeValidate, { events: 'blur' });

    [...document.querySelectorAll('.partial.lead-form')].forEach((el) => {
      // eslint-disable-next-line
      new Vue({
        el,
        components: { LeadForm },
      });
    });

    $(document.body).on('click', '.lead-form.partial', () => {
      $(document.body).addClass('focus');
    });

    $(document).on('click', (e) => {
      if (!$(e.target).closest('.lead-form.partial').length) {
        $(document.body).removeClass('focus');
      }
    });

    $('section.lead-points .slick-slider').slick({
      slidesToShow: 5,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 630,
          settings: {
            slidesToShow: 1,
          },
        },
      ]
    });

    $('section.in-page-navigation .slick-slider').slick({
      slidesToShow: 3,
      arrows: false,
      dots: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ]
    });

    $('section.testimonials .slick-slider').slick({
      slidesToShow: 3,
      infinite: false,
      arrows: false,
      dots: true,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 630,
          settings: {
            slidesToShow: 1,
          },
        },
      ]
    });
  }
}

App.init();
