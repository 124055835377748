<template>
  <div>
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :style="{ width: `${progress}%` }"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        Progress {{ progress }}%
      </div>
    </div>

    <div class="inner">
      <div
        v-show="step === 1"
        class="step"
      >
        <form
          data-vv-scope="form-1"
          @submit.prevent="changeStep"
        >
          <p
            class="question"
            v-text="leadQuestion"
          />

          <div class="form-group mb-0">
            <RadioButtons
              v-model="form.amount"
              v-validate="'required'"
              :options="['Domestic', 'Commercial']"
              name="amount"
            />

            <div
              v-if="errors.has('form-1.amount')"
              class="invalid-feedback"
              :class="{ 'd-block': errors.has('form-1.amount') }"
              v-text="errors.first('form-1.amount')"
            />
          </div>

          <button
            type="submit"
            class="btn btn-secondary btn-block btn-pulse"
          >
            Start Quote
          </button>

          <div class="security">
            <p>
              Your information is secure.
              <a
                href="/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </form>
      </div>

      <div
        v-show="step === 2"
        class="step"
      >
        <form
          data-vv-scope="form-2"
          @submit.prevent="changeStep"
        >
          <p class="question">
            Good News, we have found operators in your area.
            Enter your details below to <span class="text-primary">start your quote</span>
          </p>

          <div class="form-group">
            <input
              v-model="form['full-name']"
              v-validate="'required|min:2'"
              :class="{ 'form-control': true, 'is-invalid': errors.has('form-2.name') }"
              type="text"
              name="name"
              placeholder="Full Name*"
            >
            <span
              v-show="errors.has('form-2.name')"
              class="invalid-feedback"
              v-html="errors.first('form-2.name')"
            />
          </div>

          <div class="form-group">
            <input
              v-model="form.email"
              v-validate="'required|email'"
              :class="{ 'form-control': true, 'is-invalid': errors.has('form-2.email') }"
              type="email"
              placeholder="E-mail Address*"
              name="email"
            >
            <span
              v-show="errors.has('form-2.email')"
              class="invalid-feedback"
              v-html="errors.first('form-2.email')"
            />
          </div>

          <div class="form-group">
            <input
              v-model="form.phone"
              v-validate="'required|tel'"
              :class="{ 'form-control': true, 'is-invalid': errors.has('form-2.phone') }"
              type="tel"
              placeholder="Phone Number*"
              name="phone"
            >
            <span
              v-show="errors.has('form-2.phone')"
              class="invalid-feedback"
              v-html="errors.first('form-2.phone')"
            />
          </div>

          <div class="form-group">
            <input
              v-model="form.addressLine1"
              v-validate="'required'"
              :class="{ 'form-control': true, 'is-invalid': errors.has('form-2.addressLine1') }"
              placeholder="Survey Site Address*"
              name="addressLine1"
            >
            <span
              v-show="errors.has('form-2.addressLine1')"
              class="invalid-feedback"
              v-html="errors.first('form-2.addressLine1')"
            />
          </div>

          <div class="form-group">
            <input
              v-model="form.postcode"
              v-validate="'required|postcode'"
              :class="{ 'form-control': true, 'is-invalid': errors.has('form-2.postcode') }"
              placeholder="Postcode*"
              name="postcode"
            >
            <span
              v-show="errors.has('form-2.postcode')"
              class="invalid-feedback"
              v-html="errors.first('form-2.postcode')"
            />
          </div>

          <div class="form-group">
            <select
              v-model="form.interestedIn"
              v-validate="'required'"
              :class="{ 'custom-select': true, 'is-invalid': errors.has('form-2.interestedIn') }"
              name="interestedIn"
            >
              <option
                :value="null"
                hidden
              >
                Which Survey are you interested in?
              </option>
              <option>Tree Survey</option>
              <option>Ecology Survey</option>
              <option>BS5837 Tree Survey</option>
              <option>Measured Survey</option>
              <option>Topographical Survey</option>
              <option>Arboricultural Survey</option>
              <option>Bat Survey</option>
            </select>
            <span
              v-show="errors.has('form-2.interestedIn')"
              class="invalid-feedback"
              v-html="errors.first('form-2.interestedIn')"
            />
          </div>

          <button
            type="submit"
            :disabled="sent || sending"
            class="btn btn-secondary btn-block"
            :class="{ loader: sending }"
          >
            Get my Quotes
          </button>

          <div
            v-if="sent"
            class="alert alert-success text-center mt-4 mt-md-5 mb-0"
            v-text="sent"
          />

          <div class="disclaimer">
            <p>
              By submitting this form, you agree to be contacted to provide you with window quotes. You may be
              contacted by email or phone to confirm your product requirement. For more information see our
              <a
                href="/privacy-policy"
                target="_blank"
              >
                privacy policy
              </a>.
            </p>
          </div>
        </form>
      </div>

      <div
        v-if="error"
        class="alert alert-danger mt-4"
        v-text="error"
      />
    </div>
  </div>
</template>

<script>
  import SmoothScroll from 'smooth-scroll';
  import RadioButtons from './radio-buttons.vue';

  const STEPS_AMOUNT = 2;

  export default {
    components: { RadioButtons },
    props: {
      formId: {
        type: Number,
        required: true,
      },
      resultPageUrl: {
        type: String,
        required: true,
      },
      leadQuestion: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        step: 1,
        error: '',
        sent: '',
        form: {
          amount: 'Domestic',
          interestedIn: null,
        },
        sending: false,
        pixelEvents: [],
      };
    },
    computed: {
      progress() {
        const amount = (this.step / (STEPS_AMOUNT + 1)) * 100;

        return Math.round(amount > 33 ? amount : 33);
      },
    },
    mounted() {
      window.events.$on('set-postcode', (postcode) => {
        this.form.postcode = postcode;
      });
    },
    methods: {
      scrollTop() {
        if (window.innerWidth > 768) {
          return;
        }

        const target = this.$el.parentElement;

        (new SmoothScroll()).animateScroll(target, null);
      },

      async changeStep() {
        const valid = await this.$validator.validateAll(`form-${this.step}`);

        if (!valid) {
          return;
        }

        if (this.step === STEPS_AMOUNT) {
          this.handleSubmit();
          this.scrollTop();

          return;
        }

        this.step += 1;

        this.scrollTop();
      },

      handleSubmit() {
        this.sending = true;
        this.error = '';

        $.ajax({
          url: `/wp-json/contact-form-7/v1/contact-forms/${this.formId}/feedback`,
          method: 'POST',
          dataType: 'json',
          data: this.form,
          success: (res) => {
            this.sending = false;
            this.scrollTop();

            if (res.status === 'mail_sent') {
              if (window.fbq) {
                window.fbq('track', 'Lead');
              }

              if (window.dataLayer) {
                window.dataLayer.push({ event: 'leadformsuccessfulsubmit' });
              }

              window.location.href = this.resultPageUrl;
            } else {
              this.error = res.message;
            }
          },
          error: (error) => {
            this.sending = false;
            this.error = error.responseJSON.data;
            this.scrollTop();
          },
        });
      },
    },
  };
</script>
