import SmoothScroll from 'smooth-scroll';

export default class {
  constructor() {
    this.selector = '[data-scroll]';
  }

  watch() {
    [...document.querySelectorAll(this.selector)].forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        const destination = item.dataset.scroll;

        if (!destination) {
          return;
        }

        const target = document.querySelector(destination);

        (new SmoothScroll()).animateScroll(target);
      });
    });

    [...document.querySelectorAll('a[href^=\'#\']')].forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();

        if (item.href.split('#').length !== 2) {
          return;
        }

        let destination = item.href.split('#')[1];

        if (item.classList.contains('clear')) {
          destination = `#${destination}`;
        }

        if (!destination) {
          return;
        }

        const target = document.querySelector(destination);

        (new SmoothScroll()).animateScroll(target);
      });
    });
  }
}
